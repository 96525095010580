<script setup>
import { mdiMapMarkerOutline, mdiMagnify } from "@mdi/js";
import { watchDebounced } from "@vueuse/core";
import { vOnClickOutside } from "@vueuse/components";

const props = defineProps({
  buttonText: {
    type: String,
    default: "찾기",
  },
  label: {
    type: String,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
});
const modelValue = ref("");
const cityList = ref(["뮌헨", "베를린", "함부르크", "프랑크푸르트"]);
const filteredCityList = ref([]);
const loading = ref(false);
const isFocus = ref(false);
const showList = computed(() => {
  if (!isFocus.value) return false;
  if (loading.value) return true;
  if (modelValue.value && filteredCityList.value.length) {
    return true;
  }
  return false;
});
const router = useRouter();

watch(modelValue, () => {
  loading.value = true;
});

watchDebounced(
  modelValue,
  (value) => {
    if (value) {
      filteredCityList.value = cityList.value.filter((item) => item.includes(value));
    } else {
      filteredCityList.value = [];
    }
    loading.value = false;
  },
  { debounce: 500 },
);
const onClickSearchItem = (item) => {
  modelValue.value = item;
  isFocus.value = false;
};
const onFocus = () => {
  isFocus.value = true;
};
const localePath = useLocalePath();
const onSearch = () => {
  if (!modelValue.value) return;

  router.push({ path: localePath("properties"), query: { city: modelValue.value } });
};
</script>

<template>
  <div class="relative">
    <ZInput
      v-model="modelValue"
      class="md:p-1 md:text-2xl"
      :placeholder="placeholder"
      v-on-click-outside="() => (isFocus = false)"
      @focus="onFocus"
    >
      <template #prepend>
        <ZIcon
          :path="mdiMagnify"
          class="text-gray-300 w-[48px] h-[48px] -md:w-[36px] -md:h-[36px]"
          :class="{ '!text-primary': isFocus }"
        />
      </template>
      <template #append>
        <ZButton size="md" class="-md:hidden" @click="onSearch">{{ buttonText }}</ZButton>
      </template>
    </ZInput>

    <div
      v-if="showList"
      class="bg-white flex flex-col gap-1 p-1 absolute top-[calc(100%+2px)] w-full max-w-[450px] left-[60px] -md:left-0 -md:top-[58px] rounded-lg shadow-lg"
    >
      <button v-if="loading" class="p-2 rounded-lg text-left" disabled><ZSpinner color="dark" /></button>
      <button
        v-else
        v-for="item in filteredCityList"
        class="flex gap-2 items-center p-2 hover:bg-gray-200 cursor-pointer text-left rounded-lg focus:ring focus:ring-primary-200 focus:outline-none"
        @click="onClickSearchItem(item)"
      >
        <ZIcon :path="mdiMapMarkerOutline" class="text-gray-500 w-[24px] h-[24px]" />

        {{ item }}
      </button>
    </div>

    <ZButton size="lg" color="dark" class="flex mt-3 w-full md:hidden" @click="onSearch">
      {{ buttonText }}
    </ZButton>
  </div>
</template>
